<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            {{ $t('calculators.calculatorKSProcessingFee') }}
          </div>
          <div class="card-body">
            <div class="px-3">
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.feeType') }}:</label>
                  <select class="form-select" aria-label="Fee Type" v-model="feeType" @change="updateCalculation">
                    <option v-for="(type, index) in feeTypesOptions" :value="type.value" :key="index">{{
                        type.label
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.prepaidTime') }}:</label>
                  <input class="form-control" type="number" v-model="prepaidTimeYears" @change="updateCalculation">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.oneTimeInvestmentAmount') }}:</label>
                  <input class="form-control" type="number" v-model="oneTimeInvestmentAmount"
                         @change="updateCalculation" min="0">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.regularInvestmentAmount') }}:</label>
                  <input class="form-control" type="number" v-model="regularInvestmentAmount"
                         @change="updateCalculation">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.currency') }}:</label>
                  <select class="form-select" aria-label="Currency" v-model="currency" @change="updateCalculation">
                    <option v-for="(currency, index) in currencyOptions" :value="currency.value" :key="index">{{
                        currency.label
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.feeDiscount') }}:</label>
                  <input class="form-control" type="number" step="5" min="0" max="100" v-model="feeDiscount"
                         @change="updateCalculation">
                </div>
              </div>
            </div>
            <div class="px-3 border-top mt-4">
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.totalInvestment') }}:</label>
                  <input class="form-control" disabled v-model="formattedTotalInvestment">
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeAfterDiscountPercents') }}:</label>
                  <input class="form-control" disabled v-model="formattedFeeAfterDiscountPercents">
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeAfterDiscountAmount') }}:</label>
                  <input class="form-control" disabled v-model="formattedFeeAfterDiscountAmount">
                </div>
              </div>
<!--              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeFirstInvestment') }}:</label>
                  <input class="form-control" disabled v-model="formattedFeeFirstInvestment">
                </div>
              </div>-->
            </div>
            <div class="px-3 border-top mt-4">
              <div v-if="totalInvestment">
                <div class="row mb-3 mt-3">
                  <div class="col">{{ $t('calculators.totalInvestmentText') }}</div>
                  <div class="col-auto" id="totalInvestment">{{ formattedTotalInvestment }}</div>
                </div>
                <div class="row mb-3 mt-3">
                  <div class="col">{{ $t('calculators.feeType') }}</div>
                  <div class="col-auto" id="feeType">{{ $t('calculators.' + feeType) }}</div>
                </div>
                <div class="row mb-3 mt-3">
                  <div class="col">{{ $t('calculators.feeAfterDiscountPercents') }}</div>
                  <div class="col-auto" id="feeAfterDiscountPercents">{{ formattedFeeAfterDiscountPercents }}</div>
                </div>
                <div class="row mb-3 mt-3">
                  <div class="col">{{ $t('calculators.feeAfterDiscountAmount') }}</div>
                  <div class="col-auto" id="feeAfterDiscountAmount">{{ formattedFeeAfterDiscountAmount }}</div>
                </div>
<!--                <div class="row mb-3 mt-3">
                  <div class="col">{{ $t('calculators.feeFirstInvestment') }}</div>
                  <div class="col-auto" id="feeFirstInvestment">{{ formattedFeeFirstInvestment }}</div>
                </div>-->
                <div class="row mb-3 mt-3">
                  <div class="col" id="resultText">{{ $t('calculators.resultText') }}</div>
                </div>
                <div class="row mb-3 mt-3 d-flex justify-content-end">
                  <div class="col-auto">
                    <button class="btn btn-primary" @click="copyToClipboard">{{ $t('calculators.copy') }}</button>
                  </div>
                </div>
                <div v-if="isCopied" class="alert alert-danger mt-3" role="alert">
                  {{ $t('calculators.textAfterCopying') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feeType: null,
      feeTypesOptions: [
        {
          label: this.$t('calculators.processingFee'),
          value: 'processingFee',
        },
        {
          label: this.$t('calculators.processingFeeAccelerated'),
          value: 'processingFeeAccelerated',
        },
        {
          label: this.$t('calculators.processingFeeExpress'),
          value: 'processingFeeExpress',
        }
      ],
      currency: null,
      currencyOptions: [
        {
          label: 'CZK',
          value: 'CZK',
        },
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'EUR',
          value: 'EUR',
        }
      ],
      prepaidTimeYears: 0,
      regularInvestmentAmount: 0,
      feeDiscount: 0,
      totalInvestment: 0,
      feeAfterDiscountPercents: 0,
      feeAfterDiscountAmount: 0,
      feeFirstInvestment: 0,
      inputRefreshDataTimeout: null,
      oneTimeInvestmentAmount: 0,
      regularInvestmentAmountForCalculation: 0,
      isCopied: false,
    };
  },

  computed: {
    formattedTotalInvestment() {
      return this.formatAmount(this.totalInvestment ?? 0);
    },

    formattedFeeAfterDiscountAmount() {
      return this.formatAmount(this.feeAfterDiscountAmount ?? 0);
    },

    formattedFeeAfterDiscountPercents() {
      return this.formatPercents(this.feeAfterDiscountPercents ?? 0);
    },

    formattedFeeFirstInvestment() {
      return this.formatAmount(this.feeFirstInvestment ?? 0);
    }
  },

  methods: {
    updateCalculation() {
      let self = this;
      if (this.inputRefreshDataTimeout)
        clearTimeout(this.inputRefreshDataTimeout);

      this.inputRefreshDataTimeout = setTimeout(() => {
        self.calculate();
      }, 500);
    },

    calculate() {
      this.regularInvestmentAmountForCalculation = this.regularInvestmentAmount && this.prepaidTimeYears ? this.regularInvestmentAmount * this.prepaidTimeYears * 12 : this.regularInvestmentAmount;
      this.totalInvestment = this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount;


      if (this.feeDiscount) {
        if (this.feeDiscount > 100) {
          this.feeDiscount = 100;
        }
        this.feeAfterDiscountPercents = (1 - (this.feeDiscount / 100)) * 0.05 * 100;
        this.feeAfterDiscountAmount = (this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100;
      } else {
        this.feeAfterDiscountPercents = 0.05 * 100;
        this.feeAfterDiscountAmount = (this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100;
      }

      if (this.feeType) {
        this.feeFirstInvestment = this.calculateProcessingFee();
      }
    },

    calculateProcessingFee() {
      if (this.feeType === 'processingFee') {
        return (this.regularInvestmentAmount + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100;
      } else if (this.feeType === 'processingFeeAccelerated') {
        if (((this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100) < this.regularInvestmentAmount) {
          return this.regularInvestmentAmount * this.prepaidTimeYears * 12 * this.feeAfterDiscountPercents / 100 * 0.5;
        } else
          return this.regularInvestmentAmount + this.oneTimeInvestmentAmount * 0.5;
      } else if (this.feeType === 'processingFeeExpress') {
        if (((this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100) < this.regularInvestmentAmount) {
          return (this.regularInvestmentAmountForCalculation + this.oneTimeInvestmentAmount) * this.feeAfterDiscountPercents / 100;
        } else
          return this.regularInvestmentAmount + this.oneTimeInvestmentAmount;
      }
    },

    formatAmount(value) {
      let currency = this.currency ?? 'CZK';

      if (currency) {
        let formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      } else {
        return value.toLocaleString() + ',-';
      }
    },

    formatPercents(value) {
      //let percents = value * 100;
      return value.toFixed(2) + ' %';
    },

    copyToClipboard() {
      // Získání textu z jednotlivých elementů
      const totalInvestment = document.getElementById('totalInvestment').innerText;
      const feeType = document.getElementById('feeType').innerText;
      const feeAfterDiscountPercents = document.getElementById('feeAfterDiscountPercents').innerText;
      const feeAfterDiscountAmount = document.getElementById('feeAfterDiscountAmount').innerText;
      const feeFirstInvestment = document.getElementById('feeFirstInvestment').innerText;
      const resultText = document.getElementById('resultText').innerText;

      // Vytvoření textu, který bude zkopírován
      const textToCopy = `
      ${this.$t('calculators.totalInvestmentText')}: ${totalInvestment}
      ${this.$t('calculators.feeType')}: ${feeType}
      ${this.$t('calculators.feeAfterDiscountPercents')}: ${feeAfterDiscountPercents}
      ${this.$t('calculators.feeAfterDiscountAmount')}: ${feeAfterDiscountAmount}
      ${resultText}
    `;

      // Zkopírování textu do schránky
      navigator.clipboard.writeText(textToCopy.trim()).then(() => {
        /*alert('Text byl úspěšně zkopírován do schránky!');*/
      }).catch(err => {
        console.error('Zkopírování do schránky selhalo', err);
      });

      this.isCopied = true;
    }
  }
};
</script>