<template>

  <!-- Modal -->
  <div ref="pageModal" class="modal fade" id="pageModal" tabindex="-1" aria-labelledby="pageModalLabel"
       aria-hidden="true" :class="{ show: modalShow, 'd-block': modalActive }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="pageModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="page-title" class="form-label">{{ $t('page_admin.title') }}</label>
            <input type="text" class="form-control" id="page-title" v-model="page.title">
          </div>
          <div class="row mb-3">
            <div class="col-12 col-md-6">
              <label for="page-name" class="form-label">{{ $t('page_admin.parentPage') }}</label>
              <select class="form-select" aria-label="Parent page" v-model="page.parent_id">
                <option :value="null">{{ $t('general.noChoice') }}</option>
                <option v-for="pageO in filteredPages" :value="pageO.id">{{ pageO.title }}</option>
              </select></div>
          </div>
          <div class="mb-3">
            <div class="form-check form-switch form-switch-md d-flex align-items-center ps-0">
              <label class="form-check-label" for="tenants-visible">{{ $t('page_admin.limitVisibility') }}</label>
              <input class="form-check-input ms-3" type="checkbox" id="tenants-visible"
                     v-model="forTenants" :true-value="1" :false-value="0">
            </div>
          </div>
          <div v-if="forTenants" class="row mb-3">
            <div class="col-12 col-md-6">
              <label for="page-name" class="form-label">{{ $t('page_admin.forPartners') }}</label>
              <select class="form-select" aria-label="Parent page" v-model="page.for_tenants" multiple>
                <option :value="null">{{ $t('general.noChoice') }}</option>
                <option v-for="tenant in tenants" :value="tenant.code" :key="tenant.code">{{ tenant.code }}</option>
              </select></div>
          </div>

          <!--          <div class="row mb-3">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="heroimage">{{ $t('pages.heroImage') }}</label>
                          <input type="file" name="heroimage" class="form-control" id="heroimage" @change="onFileChanged">
                        </div>
                      </div>
                    </div>-->
          <div class="mb-3">
            <label for="page-description" class="form-label">{{ $t('page_admin.description') }}</label>
            <textarea class="form-control" id="page-description" v-model="page.description" rows="5"/>
          </div>
          <div class="mb-3">
            <label for="page-text" class="form-label">{{ $t('page_admin.content') }}</label>
            <QuillEditor ref="quill" contentType="html"
                         v-model:content="page.content" id="page-text" class="form-control" theme="snow"
                         style="min-height: 150px"/>
          </div>
          <div class="row" v-if="page?.id">
            <div class="col-12">
              <dropzone-component :selectedPageId="page.id"></dropzone-component>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-check form-switch form-switch-md d-flex align-items-center ps-0">
              <label class="form-check-label" for="page-visible">{{ $t('page_admin.visible') }}</label>
              <input class="form-check-input ms-3" type="checkbox" id="page-visible"
                     v-model="page.is_visible" :true-value="1" :false-value="0">
            </div>
          </div>
          <div class="mb-3">
            <div class="form-check form-switch form-switch-md d-flex align-items-center ps-0">
              <label class="form-check-label" for="page-download-enabled">{{ $t('page_admin.downloadEnabled') }}</label>
              <input class="form-check-input ms-3" type="checkbox" id="page-download-enabled"
                     v-model="page.download_enabled" :true-value="1" :false-value="0">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="toggleModal">{{
              $t('general.close')
            }}
          </button>
          <button v-if="page.id" type="button" class="btn btn-danger ms-2" @click="deletePage(page.id)">
            <i class="bi bi-trash"/><span class="ms-2">{{ $t('general.delete') }}</span></button>
          <button type="button" class="btn btn-primary" @click="savePage">{{ $t('general.save') }}</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="modalActive" class="modal-backdrop fade show"></div>
</template>

<script>
import http from '@/http';

const EMPTY_PAGE = {
  title: '',
  description: null,
  content: null,
  is_visible: true,
  parent_id: null,
  for_tenants: [],
  download_enabled: true
};

export default {
  props: ['title', 'pages'],

  data() {
    return {
      page: {...EMPTY_PAGE},
      modalActive: false,
      modalShow: false,
      types: null,
      file: null,
      forTenants: 0,
      tenants: []
    };
  },

  mounted() {
    this.$refs.quill.setContents('');
    this.getTenants();
  },

  computed: {
    filteredPages() {
      return this.pages.filter(pageO => pageO.id !== this.page.id);
    }
  },

  methods: {
    savePage() {
      let body = {
        title: this.page.title,
        description: this.page.description,
        content: this.$refs.quill.getContents(),
        is_visible: this.page.is_visible,
        parent_id: this.page.parent_id,
        for_tenants: this.page.for_tenants,
        download_enabled: this.page.download_enabled
      };

      let url = '/pages';
      if (this.page.id) {
        url += '/' + this.page.id + '/update';
      }

      http.post(url, body).then(response => {
        this.emitter.emit('pageSaved', response.data.page);
        this.toggleModal();
      })
          .catch(error => {
            console.error(error);
          });
    },

    toggleModal(page = null) {
      const body = document.querySelector("body");
      this.modalActive = !this.modalActive;
      this.modalActive
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      if (this.modalActive) {
        this.page = page ? {...page} : {...EMPTY_PAGE};
        this.forTenants = this.page.for_tenants && this.page.for_tenants.length ? 1 : 0;
      }
      if (!this.modalActive) {
        this.page = {...EMPTY_PAGE};
        this.$refs.quill.setContents("");
      }
      setTimeout(() => (this.modalShow = !this.modalShow), 10);
    },

    getTenants() {
      let self = this;
      self.isLoading = true;
      http.get('/tenants/get', { params: {
          ...this.serverParams
        } }).then((response) => {
        self.tenants = response.data.tenants;
        self.isLoading = false;
      });
    },

    deletePage(id) {
      http.post('/pages/' + id + '/delete')
          .then(response => {
            this.$router.push({ name: 'PagesIndexView'});
          })
          .catch(error => {
            console.error(error);
          });
    },
  }
};
</script>

<style scoped>

</style>

