<template>
  <div class="embed-wrap">
    <div class="card p-3">
      <div class="card-body">
        <ChartViewWithFiltersComponent ref="chartView" :embedded="true"></ChartViewWithFiltersComponent>
      </div>
    </div>
  </div>
</template>

