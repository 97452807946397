<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ page?.title }}</h3>
      </div>
<!--      <div>
        <router-link class="page&#45;&#45;breadcrumbs" :to="{ name: 'ItemsIndexView' }" title=""
        ><i class="bi bi-arrow-left me-2"></i
        >{{ $t('general.backToList') }}
        </router-link
        >
      </div>-->
    </div>
<!--    <div class="page-content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.name') }}:</div>
                <div class="col-6 fw-bold">{{ item?.name }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.type') }}:</div>
                <div class="col-6">{{
                    item?.type ? (item?.type === 'STRATEGY' ? $t('item_detail.strategy') : $t('item_detail.stocks')) : '-&#45;&#45;'
                  }}
                </div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.ISIN') }}:</div>
                <div class="col-6">{{ item?.isin ?? '-&#45;&#45;' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.EodSymbol') }}:</div>
                <div class="col-6">{{ item?.eod_symbol ?? '-&#45;&#45;' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.market') }}:</div>
                <div class="col-6">{{ item?.market ?? '-&#45;&#45;' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.ticker') }}:</div>
                <div class="col-6">{{ item?.ticker ?? '-&#45;&#45;' }}</div>
              </div>
              <div class="row py-1">
                <div class="col-6">{{ $t('item_detail.currency') }}:</div>
                <div class="col-6">{{ item?.currency ?? '-&#45;&#45;' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          &lt;!&ndash; <div class="row mb-3">
            <InfoCardComponent
              title="Celkové zhodnocení"
              :content="
                item?.total_evaluation ? item.total_evaluation.toFixed(2) + ' %' : '-&#45;&#45;'
              "
            ></InfoCardComponent>
          </div> &ndash;&gt;
          <div class="row">
            <div class="col-6">
              <InfoCardComponent
                  v-if="item"
                  :title="$t('item_detail.yieldFromStart')"
                  :content="
                 item.temp_yield_from_start
                  ? item.temp_yield_from_start.toFixed(2) + ' %'
                  : '-&#45;&#45;'
              "
                  :evaluation="true"
                  :evaluation_value="item.temp_yield_from_start"
                  class="mb-3"
              ></InfoCardComponent>
            </div>
            <div class="col-6">
              <InfoCardComponent
                  v-if="item"
                  :title="$t('item_detail.YTDEvaluation')"
                  :content="
                item &&  item?.yield_current_year
                  ? item.yield_current_year.toFixed(2) + ' %'
                  : '-&#45;&#45;'
              "
                  :evaluation="true"
                  :evaluation_value="item.yield_current_year"
              ></InfoCardComponent>
            </div>

&lt;!&ndash;            <div>
              <div class="card mt-3" v-if="item && item.years_yields">
                <div class="card-header fw-bold">{{ $t('item_detail.yieldInYears') }}</div>
                <div class="card-body px-4 py-1">
                  <div class="row border-bottom">
                    <div class="col p-2" v-for="year in item.years_yields">
                      {{ year.year }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col p-2" v-for="year in item.years_yields">
                      <strong>{{ year.value.toFixed(2) + ' %' }}</strong>
                      <span v-if="year.value > 0" class="ms-2"><font-awesome-icon v-if="year.value > 0" icon="arrow-up"
                                                                                  class="text-success"></font-awesome-icon></span>
                      <span v-if="year.value < 0" class="ms-2"><font-awesome-icon v-if="year.value < 0"
                                                                                  icon="arrow-down"
                                                                                  class="text-danger"></font-awesome-icon></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>&ndash;&gt;
          </div>
        </div>
      </div>
      <div
          v-if="item && item.fundamental_data && item.fundamental_data.ETF_Data && item.fundamental_data.ETF_Data.Performance"
          class="row">
        <div class="col-12">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.performance') }}</div>
            <div class="card-body px-4 py-1">
              <div class="row border-bottom">
                <div class="col p-2" v-for="performanceValue in performanceValues">
                  {{ $t('item_detail.' + performanceValue) }}
                </div>
              </div>
              <div class="row">
                <div class="col p-2" v-for="performanceValue in performanceValues">
                  <strong>{{ item.fundamental_data.ETF_Data.Performance[performanceValue] + ' %' }}</strong>
                  <span v-if="item.fundamental_data.ETF_Data.Performance[performanceValue] > 0" class="ms-2"><font-awesome-icon
                      v-if="item.fundamental_data.ETF_Data.Performance[performanceValue] > 0" icon="arrow-up"
                      class="text-success"></font-awesome-icon></span>
                  <span v-if="item.fundamental_data.ETF_Data.Performance[performanceValue] < 0" class="ms-2"><font-awesome-icon
                      v-if="item.fundamental_data.ETF_Data.Performance[performanceValue] < 0" icon="arrow-down"
                      class="text-danger"></font-awesome-icon></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item && item.fundamental_data && item.fundamental_data.ETF_Data && item.fundamental_data.ETF_Data"
           class="row mt-3">
        <div class="col-6">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.top10Holdings') }}</div>
            <div class="card-body px-4 py-1">

              <div class="row py-1 border-bottom"
                   v-for="propertyName in Object.keys(item.fundamental_data.ETF_Data.Top_10_Holdings)"
                   v-bind:key="propertyName">
                <div v-if="item.fundamental_data.ETF_Data.Top_10_Holdings[propertyName].Name == null">
                  {{ $t('general.noInformation') }}
                </div>
                <div v-else>
                  <div class="col-8">{{
                      item.fundamental_data.ETF_Data.Top_10_Holdings[propertyName].Name + ' (' + item.fundamental_data.ETF_Data.Top_10_Holdings[propertyName].Code + ')'
                    }}:
                  </div>
                  <div class="col-4">{{
                      item.fundamental_data.ETF_Data.Top_10_Holdings[propertyName]['Assets_%'] + ' %'
                    }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.sectorWeights') }}</div>
            <div class="card-body px-4 py-1">

              <div class="row py-1 border-bottom"
                   v-for="propertyName in Object.keys(item.fundamental_data.ETF_Data.Sector_Weights)"
                   v-bind:key="propertyName">
                <div class="col-8">{{ propertyName }}:</div>
                <div class="col-4">{{
                    item.fundamental_data.ETF_Data.Sector_Weights[propertyName]['Equity_%'] + ' %'
                  }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div v-if="item?.description" class="row mt-3">
            <InfoCardComponent
                :title="$t('item_detail.description')"
                :content="item?.description"
                :languages="languages"
                :html="true"
            ></InfoCardComponent>
          </div>
        </div>
      </div>
    </div>-->

<!--    <div class="card mt-4">
      <div class="card-body">
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
            enabled: true,
            perPage: 20,
            position: 'bottom',
            nextLabel: $t('general.next'),
            prevLabel: $t('general.previous'),
            perPageDropdownEnabled: false,
          }"
            :sort-options="{
            enabled: true,
          }"
        >
          <template #emptystate v-if="!isLoading">
            <div style="font-weight: bold; text-align: center">
              {{ $t('general.noData') }}
            </div>
          </template>
          <template #emptystate v-if="isLoading">
            <div style="font-weight: bold; text-align: center">
              {{ $t('general.loadingData') }}
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>-->
  </div>
</template>

<script>
import http from '@/http';

export default {
  data() {
    return {
      page: null,
      isLoading: false,
    };
  },

  mounted() {
    this.getPage();
   /* this.getItemData();
    let self = this;
    this.emitter.on('filterApplied', (obj) => {
      self.selectedYear = obj.year;
      self.from = obj.from;
      self.to = obj.to;
      self.getItemData();
    });*/
  },

  methods: {
    getPage() {
      let self = this;
      http.get('/pages/' + self.$route.params.id).then((response) => {
        self.page = response.data.page;
      });
    },

    /*getItemData() {
      let self = this;
      self.isLoading = true;
      if (self.selectedYear !== self.$t('chart.customPeriod')) {
        if (self.selectedYear.startsWith('YTD')) {
          self.from = new Date().getFullYear() + '-01-01';
          self.to = new Date().toISOString().split('T')[0];
        } else if (self.selectedYear === this.$t('chart.fromStart')) {
          self.customRange = true;
          self.from = self.item.foundation_date.split('T')[0];
        } else {
          self.selectedYear = self.selectedYear.slice(0, 4);
          self.from = self.selectedYear + '-01-01';
          self.to = self.selectedYear + '-12-31';
        }
      } else {
        self.from = self.selectedFrom;
        self.to = self.selectedTo;
      }

      /!* if (this.selectedYear !== self.$t('chart.customPeriod')) {
         self.from =
             self.selectedYear === 'YTD'
                 ? new Date().getFullYear() + '-01-01'
                 : self.selectedYear + '-01-01';
         self.to =
             self.selectedYear === 'YTD'
                 ? new Date().getFullYear() + '-12-31'
                 : self.selectedYear + '-12-31';
       }*!/
      let path = '/items/item-data?code=' + self.$route.params.code;
      if (self.from) {
        path = path + '&from=' + self.from;
      }
      if (self.to) {
        path = path + '&to=' + self.to;
      }
      http.get(path).then((response) => {
        self.rows = response.data;
        self.isLoading = false;
        /!*  self.$refs.chartView?.$refs.chart.getChartData(
          self.$route.params.code,
          self.from,
          self.to
        ); *!/
      });
    },*/

    /*formatPercentage: function (value) {
      return parseFloat(value).toFixed(2) + ' %';
    },

    formatNumber: function (value) {
      return parseFloat(value).toFixed(2);
    },

    parseDate: function (value) {
      return new Date(value).toLocaleDateString();
    }*/
  }
};
</script>
