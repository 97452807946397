<template>
  <div>
    <div class="card">
      <div class="card-header fw-bold">{{ title }}</div>
      <div>
        <ul class="nav nav-tabs" v-if="languages">
          <li class="nav-item" v-for="lang in languages">
            <a class="nav-link" :class="{ active: selectedLang === lang }"
               href="#"
               @click.prevent="selectLanguage(lang)">{{ $t('general.' + lang) }}
              <small v-if="parsedContent[lang] && parsedContent[lang].is_machine_translated" class="text-dark">
                (Přeloženo strojově)</small></a>
          </li>
        </ul>
      </div>
      <div v-if="html && !languages" class="card-body" v-html="content"></div>
      <div v-else-if="html && languages" class="card-body" v-html="computedContent"></div>
      <div v-else class="card-body text-center">{{ content }}
        <span v-if="evaluation">
          <span v-if="evaluation_value > 0"><font-awesome-icon v-if="evaluation_value > 0" icon="arrow-up"
                                                               class="text-success"></font-awesome-icon></span>
          <span v-if="evaluation_value < 0"><font-awesome-icon v-if="evaluation_value < 0" icon="arrow-down"
                                                               class="text-danger"></font-awesome-icon></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'content', 'html', 'evaluation', 'evaluation_value', 'languages'],

  data() {
    return {
      selectedLang: 'cs',
      parsedContent: [],
    };
  },

  methods: {
    selectLanguage(lang) {
      this.selectedLang = lang;
    },
  },

  computed: {
    computedContent() {
      let self = this;
      if (this.languages) {
        //self.parsedContent = JSON.parse(this.content);
        self.parsedContent = this.content;
        if (self.parsedContent[self.selectedLang] && self.parsedContent[self.selectedLang]['value'] != "null") {
          return self.parsedContent[self.selectedLang]['value'];
        } else {
          return "---";
        }
      } else {
        return this.content;
      }
    },
  },
};
</script>
