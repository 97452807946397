<template>
  <div class="embed-wrap">
    <div class="card p-3">
      <div class="card-body">
        <CalculatorViewWithFiltersComponent ref="calculatorView"></CalculatorViewWithFiltersComponent>
      </div>
    </div>
  </div>
</template>

