<template>
  <div>
    <!--    <div v-if="isLoading" id="loader-wrap">
          <div class="loader">
            <div class="loader-content">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="mt-3">Načítám...</p>
            </div>
          </div>
        </div>-->
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('pages.pages') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <!--      <div class="filter">
              <div class="form-group filter-search-input">
                <input v-on:keyup="onSearch" class="form-control" :placeholder="$t('items_list.searchItem')"
                       v-model="serverParams.searchTerm"/>
                <span class="icon"><i class="bi bi-search"></i></span>
              </div>
            </div>-->
      <div class="mt-4 text-end" v-show="editAllowed">
        <button
            class="btn btn-primary"
            @click="openModal"
        ><i class="bi bi-file-earmark-plus"></i
        ><span class="ps-2">{{ $t('page_admin.addPage') }}</span></button>
      </div>
      <div v-if="isLoading">
        <div class="loader">
          <div class="loader-content d-flex align-items-center">
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <span class="ms-2">{{ $t('general.loadingData') }}.</span>
          </div>
        </div>
      </div>
      <ul v-else class="pages-list pages-list--big">
        <li v-for="page in pages" :key="page.slug" class="d-flex d-inline align-items-center">
          <div v-show="editAllowed" class="me-3">
            <button type="button" class="btn border me-2" :class="{ 'grey-arrow': page.is_first}"
                    :disabled="page.is_first"
                    @click="moveUp(page.id)">▲
            </button>
            <button type="button" class="btn border" :class="{ 'grey-arrow': page.is_last}" :disabled="page.is_last"
                    @click="moveDown(page.id)">▼
            </button>
          </div>
          <router-link :to="{ name: 'PagesDetailView', params: { slug: page?.slug } }" class="pages-list-item">
            <div class="pages-list-item-title-wrap">
              <span class="pages-list-item-title">{{ page.title }} <i class="bi bi-eye-slash"
                                                                      v-if="!page.is_visible"></i><i
                  class="bi bi-chevron-right"></i></span>
            </div>
            <p class="pages-list-item-description">{{ page.description }}</p>
          </router-link>
        </li>
      </ul>
      <!--      <div class="mt-4">
              <vue-good-table
                  :columns="columns"
                  :rows="pages"
                  :pagination-options="{
                enabled: true,
                perPage: 20,
                position: 'bottom',
                nextLabel: $t('general.next'),
                prevLabel: $t('general.previous'),
                perPageDropdownEnabled: false,
              }"
                  :sort-options="{
                enabled: true,
              }"
              >
                <template #emptystate>
                  <div v-if="!isLoading" style="font-weight: bold; text-align: center">
                    {{ $t('general.noData') }}
                  </div>
                  <div v-if="isLoading" style="font-weight: bold; text-align: center">
                    {{ $t('general.loadingData') }}
                  </div>
                </template>
                &lt;!&ndash; <template #loadingContent>
                  <div style="font-weight: bold; text-align: center">
                    Nahrávají se data.
                  </div>
                </template> &ndash;&gt;
                <template #table-row="props">
                   <span v-if="props.column.field == 'is_visible'">
                     <span v-if="props.row.is_visible">{{ $t('general.' + props.row.is_visible) }}</span></span>

                  <span v-if="props.column.field == 'action'" style="font-weight: bold; float: right">
                    <button
                        class="btn btn-primary me-2"
                        @click="openModal(props.row.id)"
                    ><i class="bi bi-pencil"></i
                    ></button>
                    &lt;!&ndash;             <router-link
                                     class="btn btn-primary"
                                     :to="{ name: 'PageAdminDetailView', params: { id: props.row.id } }"
                                     :title="$t('page_admin.showDetail')"
                                 ><i class="bi bi-eye"></i
                                 ></router-link>&ndash;&gt;
                    <button type="button" class="btn btn-primary ms-2" @click="deletePage(props.row.id)">
                                          <i class="bi bi-trash"/></button>

                </span>
                </template>
              </vue-good-table>
            </div>-->
    </div>
    <Modal ref="PageAdminModal" :pages="pages"/>
  </div>
</template>

<script>
import http from '@/http';
import Modal from "@/components/page_admin/PageAdminModal.vue";
import TokenService from "@/services/token-service.js";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      showLoader: true,
      pages: [],
      isLoading: false,
      editAllowed: false,
    };
  },

  mounted() {
    this.loadPages();
    this.emitter.on('pageSaved', (page) => {
      this.loadPages();
    });
    this.canEdit();

    /*setTimeout(function(){
      http.post('/auth/me').then((response) => {
        console.log(response);
      });
    }, 2000);*/
  },

  methods: {
    loadPages() {
      let self = this;
      self.isLoading = true;
      http.get('/pages?list=simple').then((response) => {
        self.pages = response.data.pages.filter(page => {
          const isRootPage = page.parent_id === null;
          //const tenantId = window.settings.tenant_id;
          if (this.editAllowed) {
            return isRootPage;
          }

          const tenantCode = window.settings.tenant_code;
          if (tenantCode == null) {
            return isRootPage && page.for_tenants == null;
          }

          // Pokud page.for_tenants je null nebo undefined, považujeme to za prázdné pole
          const isForTenant = Array.isArray(page.for_tenants) && page.for_tenants.includes(tenantCode) || page.for_tenants === null;

          // Zobrazí pouze root stránky, které jsou určeny pro daného tenant ID
          return isRootPage && isForTenant;
        });
        self.isLoading = false;
      });
    },

    openModal(id) {
      let page = this.pages.find(page => page.id === id);
      this.$refs.PageAdminModal.toggleModal(page);
    },

    canEdit() {
      this.editAllowed = TokenService.getRoleLevel() === 5;
    },

    moveUp(id) {
      let clickedPageIndex = this.pages.findIndex(page => page.id === id);
      let previousPageId = this.pages[clickedPageIndex - 1].id;
      let body = {
        previous_page_id: previousPageId
      };

      http.post('/pages/' + id + '/move-up', body)
          .then(response => {
            this.pages = response.data.pages;
          })
          .catch(error => {
            console.error(error);
          });
    },

    moveDown(id) {
      let clickedPageIndex = this.pages.findIndex(page => page.id === id);
      let nextPageId = this.pages[clickedPageIndex + 1].id;
      let body = {
        next_page_id: nextPageId
      };

      http.post('/pages/' + id + '/move-down', body)
          .then(response => {
            this.pages = response.data.pages;
          })
          .catch(error => {
            console.error(error);
          });
    },

    /*
       formatPageColumn(value, row) {
      const depth = row.depth || 0;
      const indent = '-'.repeat(depth * 3);
      return `${indent} ${value}`;
    }*/
  },
};
</script>
