<template>
  <div>
    <div class="row mb-3 justify-content-center">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-4">
            <input
                v-model="selectedCalculator"
                type="radio"
                class="btn-check"
                name="options"
                id="option2"
                autocomplete="off"
                value="calculatorRP"
                @change="calculatorSelected()"
            />
            <label class="btn btn-outline-primary d-block" for="option2">{{
                $t('calculators.calculatorRP')
              }}</label>
          </div>
          <div class="col-4">
            <input
                v-model="selectedCalculator"
                type="radio"
                class="btn-check"
                name="options"
                id="option1"
                autocomplete="off"
                value="calculatorKS"
                @change="calculatorSelected()"
            />
            <label class="btn btn-outline-primary d-block" for="option1">{{
                $t('calculators.calculatorKS')
              }}</label>
          </div>
          <div class="col-4">
            <input
                v-model="selectedCalculator"
                type="radio"
                class="btn-check"
                name="options"
                id="option3"
                autocomplete="off"
                value="calculatorMultiple"
                @change="calculatorSelected()"
            />
            <label class="btn btn-outline-primary d-block" for="option3">{{
                $t('calculators.calculatorMultiple')
              }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-xl-6">
        <div v-if="selectedCalculator === 'calculatorKS'">
          <CalculatorsKSComponent></CalculatorsKSComponent>
        </div>

        <div v-if="selectedCalculator === 'calculatorRP'">
          <CalculatorsRPComponent :multiple="false"></CalculatorsRPComponent>
        </div>

        <div v-if="selectedCalculator === 'calculatorMultiple'">
          <CalculatorsRPComponent :multiple="true"></CalculatorsRPComponent>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/http';

export default {
  props: [],
  data() {
    return {
      selectedCalculator: 'calculatorRP',
    };
  },

  mounted() {
  },

  methods: {
    calculatorSelected() {

    },


    formatNumber(value) {
      return value ? value.toLocaleString() : null;
    },

    formatAmount(value) {
      let currency = this.selectedProduct?.currency ?? 'CZK';

      if (currency) {
        let formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      } else {
        return value.toLocaleString() + ',-';
      }
    },

    formatPercents(value) {
      let percents = value * 100;
      return percents.toFixed(2) + ' %';
    },
  },

  computed: {},
};
</script>