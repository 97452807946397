<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('vs_generator.variableSymbols') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-6">
          <div class="card">
            <div class="card-header">
              {{ $t('vs_generator.VSGenerator') }}
            </div>
            <div class="card-body">
              <div class="px-3">
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="form-label">{{ $t('vs_generator.contractNumber') }}:</label>
                    <input class="form-control" type="text" minlength="8" maxlength="8" step="1"
                           v-model="contractNumber" @input="validateContractNumberInput">
                    <p v-if="!isValid" class="text-danger">Zadejte právě 8 číslic.</p>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="form-label">{{ $t('vs_generator.strategy') }}:</label>
                    <select class="form-select" aria-label="Strategy" v-model="selectedStrategies" multiple>
                      <option v-for="(strategy, index) in strategies" :value="strategy" :key="index">{{
                          strategy.name
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="form-label">{{ $t('vs_generator.bankAccount') }}:</label>
                    <select class="form-select" aria-label="Account" v-model="selectedAccount">
                      <option v-for="(account, index) in localeAccounts" :value="account" :key="index">{{
                          this.$i18n.locale === 'sk' ? account.IBAN + " (" + $t('calculators.currency') + ': ' + account.currency + ')' : account.account_number + "/" + account.bank_code + " (" + $t('calculators.currency') + ': ' + account.currency + ')'
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--                <div class="row mb-3">
                                  <div class="col-12">
                                    <label class="form-label">{{ $t('vs_generator.variableSymbol') }}:</label>
                                    <input class="form-control" type="text" disabled v-model="generatedVariableSymbol">
                                  </div>
                                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--      <div id="textForClients">{{ $t('vs_generator.clientInfo') }}

            </div>
            <div class="mt-3 mb-3">
              <div class="row">
                <div class="col fw-bold">{{ $t('vs_generator.bankAccount') }}</div>
                <div class="col-auto" id="bankAccount">
                  {{
                    this.$i18n.locale === 'sk' ? selectedAccount.IBAN : selectedAccount.account_number + "/" + selectedAccount.bank_code
                  }}
                </div>
              </div>-->


      <div v-if="selectedStrategies.length > 0" class="row justify-content-center mt-3">
        <div class="col-12 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div id="textForClients">{{ $t('vs_generator.clientInfo') }}</div>
              <div class="mt-3 mb-3">
                <div class="row">
                  <div id="'bankAccount" class="col fw-bold">{{ $t('vs_generator.bankAccount') }}</div>
                  <div class="col-auto" id="bankAccount">
                    {{
                      this.$i18n.locale === 'sk' ? selectedAccount.IBAN : selectedAccount.account_number + "/" + selectedAccount.bank_code
                    }}
                  </div>
                </div>
              </div>


              <div class="mt-3 mb-3 pb-3 border-bottom" v-for="strategy in selectedStrategies" :key="strategy.id">
                <div v-if="strategy && contractNumber">
                  <h5>{{ strategy.name }}</h5>
                  <div class="row mt-3">
                    <div class="col fw-bold">{{ $t('vs_generator.variableSymbol') }}</div>
                    <div class="col-auto">{{ generateVariableSymbol(strategy) }}</div>
                  </div>

                  <div class="d-flex justify-content-center mt-3">
                    <qrcode-vue
                        :value="generateQRCodeText(strategy)"
                        :size="200"
                        level="H"
                        render-as="canvas"
                        :ref="'qrcodeCanvas-' + strategy.id"
                        :id="'qrcodeCanvas-' + strategy.id"
                    />
                  </div>
                  <div class="row mb-3 mt-3 d-flex justify-content-end">
<!--                    <div class="col-auto">
                      <button @click="copyToClipboard(strategy)" class="btn btn-primary">
                        {{ $t('calculators.copyText') }}
                      </button>
                      <button @click="copyQRCodeToClipboard(strategy)" class="btn btn-primary ms-2">
                        {{ $t('calculators.copyQR') }}
                      </button>
                    </div>-->

                    <!--                  // text after copying
                                      <div v-if="isCopied" class="alert alert-danger mt-3" role="alert">
                                        {{ $t('calculators.textAfterCopying') }}
                                      </div>-->
                  </div>
                </div>
                <!--              <div class="row mb-3 mt-3 d-flex justify-content-end">
                             <div class="col-auto">
                               <button class="btn btn-primary" @click="copyToClipboard">{{ $t('calculators.copy') }}</button>
                             </div>
                           </div>
                           <div v-if="isCopied" class="alert alert-danger mt-3" role="alert">
                             {{ $t('calculators.textAfterCopying') }}
                           </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http.js";
import QrcodeVue, {QrcodeCanvas, QrcodeSvg} from 'qrcode.vue';
import {jsPDF} from "jspdf";
import ClipboardJS from 'clipboard';

export default {
  components: {
    QrcodeVue,
    QrcodeCanvas,
    QrcodeSvg,
  },

  data() {
    return {
      contractNumber: null,
      isValid: true,
      strategies: [],
      selectedStrategies: [],
      accounts: {
        cs: [
          {account_number: '0321171708', bank_code: '0300', IBAN: 'CZ0203000000000321171708', currency: 'CZK'},
          {
            account_number: '321171759',
            bank_code: '0300',
            IBAN: 'CZ8003000000000321171759',
            SWIFT: 'CEKOCZPP',
            currency: 'EUR'
          },
          {
            account_number: '321171812',
            bank_code: '0300',
            IBAN: 'CZO703000000000321171812',
            SWIFT: 'CEKOCZPP',
            currency: 'USD'
          },
        ],
        sk: [
          {
            account_number: '',
            bank_code: '',
            IBAN: 'SK6475000000000255169713',
            SWIFT: '',
            currency: 'EUR'
          },
        ],
      },
      selectedAccount: null,
      isCopied: false,
    };
  },

  mounted() {
    this.getAllStrategies();
    if (this.localeAccounts.length > 0) {
      this.selectedAccount = this.localeAccounts[0];
    }
  },

  computed: {
    localeAccounts() {
      let self = this;
      self.selectedAccount = this.accounts[this.$i18n.locale][0];
      return this.accounts[this.$i18n.locale];
    },
  },

  methods: {
    getAllStrategies() {
      let self = this;
      let url = '/items/get-all-strategies';

      if (self.$route.query.locale) {
        url = url + '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        url = url + '?locale=' + this.$i18n.locale;
      } else {
        url = url + '?locale=cs';
      }

      if (window.tenant_code) {
        url = url + '&tenant_code=' + window.tenant_code;
      }

      http.get(url).then((response) => {
        self.strategies = response.data.items;
      });
    },

    generateVariableSymbol(strategy) {
      if (this.contractNumber && strategy?.number) {
        return this.contractNumber + strategy.number.substring(2);
      }
      return "---";
    },
    generateQRCodeText(strategy) {
      if (this.contractNumber && strategy && this.selectedAccount) {
        return `SPD*1.0*ACC:${this.selectedAccount.IBAN}*AM:0*CC:${strategy.currency}*MSG:-*X-VS:${this.generateVariableSymbol(strategy)}`;
      }
      return null;
    },

    validateContractNumberInput(event) {
      let value = event.target.value.replace(/\D/g, '');

      if (value.length > 8) {
        value = value.slice(0, 8);
      }

      // Aktualizace vstupu
      this.contractNumber = value;

      // Validace, jestli je počet číslic přesně 8
      this.isValid = value.length === 8;
    },

    copyToClipboard(strategy) {
      // Získání textu z jednotlivých elementů
      const textForClients = document.getElementById('textForClients').innerText;
      const bankAccount = document.getElementById('bankAccount').innerText;

      // Vytvoření textu, který bude zkopírován
      const textToCopy = `
        ${textForClients}
        ${this.$t('vs_generator.bankAccount')}: ${bankAccount}
        ${strategy.name}
        ${this.$t('vs_generator.variableSymbol')}: ${this.generateVariableSymbol(strategy)}
    `;

      navigator.clipboard.writeText(textToCopy.trim()).then(() => {
        /*alert('Text byl úspěšně zkopírován do schránky!');*/
      }).catch(err => {
        console.error('Zkopírování do schránky selhalo', err);
      });

      /*navigator.clipboard.write([
        new ClipboardItem({
          'image/svg+xml': svgBlob
        })
      ]).then(() => {
        alert('Text byl úspěšně zkopírován do schránky!');
      }).catch(err => {
        console.error('Zkopírování do schránky selhalo', err);
      });*/

      /*new ClipboardJS('.copy-button', {
        text: () => pdfUrl
      });
      URL.revokeObjectURL(pdfUrl); // Uvolnění URL

      const copyButton = document.createElement('button');
      copyButton.className = 'copy-button';
      document.body.appendChild(copyButton);*/

// Simulace kliknutí
      /*copyButton.click();

      document.body.removeChild(copyButton);
      // Ladicí výpis
      console.log("PDF URL copied to clipboard");*/

      //this.isCopied = true;
    },

    copyQRCodeToClipboard(strategy) {
      let dataURL = document.getElementById('qrcodeCanvas-' + strategy.id).toDataURL('image/png');
      //const canvas = this.$refs['qrcodeCanvas-' + strategy.id];
      /*
       //const canvas = this.$refs.qrcodeCanvas.$el;
       const dataURL = canvas.toDataURL('image/png');*/
      //const blob = fetch(canvasUrl).blob();
      //console.log(canvas);
      const parts = dataURL.split(',');
      const byteString = atob(parts[1]); // Dekódování Base64 části
      const mimeString = parts[0].match(/:(.*?);/)[1]; // Typ MIME
      const byteArray = new Uint8Array(byteString.length);

      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], {type: mimeString});

      const imageItem = new ClipboardItem({'image/png': blob});

      // Zapsat do schránky
      navigator.clipboard.write([imageItem]).then(() => {
        alert('QR code copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy QR code: ', err);
      });

    }
  }
};
</script>