<template>
  <div>
    <div class="card mb-3">
      <div class="card-header fw-bold">{{ $t('pages.documents') }}</div>
      <div class="card-body">
        <div class="card-text pb-3 mb-2 " v-if="!page?.documents.length">{{ $t('pages.noDocuments') }}</div>
        <div class="card-text" v-else>
          <div class="row pb-2 align-items-center documents--item" v-for="document in page.documents"
               :key="document.uuid">
            <div class="col-8">
               <span
                   class="me-2"
                   style="vertical-align: middle"
                   :class="'file-icon file-icon__' + document.extension"
               >
                    {{ document.extension }}
                  </span>
              <span class="ml-2">{{ document.file_name }}</span>
            </div>
            <div class="col-4 text-end">
              <router-link
                  :to="{ name: 'DocumentDetailView', params: { uuid: document.uuid } }"
              >
                <button class="btn btn-primary"><i class="bi-eye"></i>
                </button>
              </router-link
              >
              <button v-if="page.download_enabled" class="btn btn-primary ms-2" @click="documentDownload(document)"><i
                  class="bi bi-download"></i></button>
              <button v-if="editAllowed" class="btn btn-danger ms-2" @click="deleteDocument(document.uuid)"><i
                  class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--      <v-card-text>

            <div v-for="document in showedDocuments">
              <v-row align="center">
                <v-col class="v-col-auto" text="ed">
                  <span
                    style="vertical-align: middle"
                    :class="'file-icon file-icon__' + document.extension"
                  >
                    {{ document.extension }}
                  </span>
                  <span class="ml-2">{{ document.name }}</span>
                </v-col>
                <v-col text="ed" style="text-align: right">
                  <router-link
                    v-if="canViewFile(document)"
                    :to="{ name: 'DocumentDetail', params: { hash: document.hash } }"
                  >
                    <v-btn variant="flat" size="x-small">
                      <v-icon icon="mdi-eye" color="primary"></v-icon></v-btn></router-link
                  ><v-btn variant="flat" @click="documentDownload(document)">
                    <v-icon icon="mdi-file-download" color="primary"></v-icon> </v-btn
                  ><v-btn
                    v-if="isAdmin()"
                    variant="flat"
                    size="x-small"
                    @click="documentModalOpen(document.id)"
                  >
                    <v-icon icon="mdi-pencil" color="primary"></v-icon></v-btn
                ></v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"></v-divider>
            </div>
            <v-card-actions
              v-if="
                !showMoreDocuments && !showAllDocuments && Object.keys(documents).length > 5
              "
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" variant="flat" v-on:click="getAllDocuments()">{{
                $t('documents.more')
              }}</v-btn></v-card-actions
            >
          </v-card-text>-->
  </div>
</template>

<script>
import http from "@/http.js";
import TokenService from "@/services/token-service.js";

export default {
  props: ['page'],

  data() {
    return {
      editAllowed: false
    };
  },

  mounted() {
    this.canEdit();
  },

  computed: {},

  methods: {
    documentDownload(doc) {
      let self = this;
      http
          .post('/pages/' + self.page.id + '/files/' + doc.uuid + '/download', {}, {
            responseType: 'arraybuffer',
          })
          .then((response) => {
            // Vytvoření Blob objektu z binárních dat
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', doc.file_name + '.' + doc.extension);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
    },

    /*getAllDocuments() {
      this.showMoreDocuments = true;
      this.showAllDocuments = true;
      this.getDocuments();
    },*/

    canEdit() {
      this.editAllowed = TokenService.getRoleLevel() === 5;
    },

    deleteDocument(uuid) {
      let self = this;
      http.post('/pages/' + self.page.id + '/files/' + uuid + '/delete').then((response) => {
        self.page.documents.find((document, index) => {
          if (document.uuid === uuid) {
            self.page.documents.splice(index, 1);
          }
        });
      });
    },

  },
};
</script>
