import cs_auth from './cs/auth.json';
import cs_general from './cs/general.json';
import cs_chart from './cs/chart.json';
import cs_item_detail from './cs/item_detail.json';
import cs_items_list from './cs/items_list.json';
import cs_items_categories from './cs/items_categories.json';
import cs_items_types from './cs/items_types.json';
import cs_tenants_list from './cs/tenants_list.json';
import cs_offers from './cs/offers.json';
import cs_calculators from './cs/calculators.json';
import cs_page_admin from './cs/page_admin.json';
import cs_pages from './cs/pages.json';
import cs_vs_generator from './cs/vs_generator.json';
import sk_auth from './sk/auth.json';
import sk_general from './sk/general.json';
import sk_chart from './sk/chart.json';
import sk_item_detail from './sk/item_detail.json';
import sk_items_list from './sk/items_list.json';
import sk_items_categories from './sk/items_categories.json';
import sk_items_types from './sk/items_types.json';
import sk_tenants_list from './sk/tenants_list.json';
import sk_offers from './sk/offers.json';
import sk_calculators from './sk/calculators.json';
import sk_page_admin from './sk/page_admin.json';
import sk_pages from './sk/pages.json';
import sk_vs_generator from './sk/vs_generator.json';

export const languages = {
    cs: {
        auth: cs_auth,
        general: cs_general,
        chart: cs_chart,
        item_detail: cs_item_detail,
        items_list: cs_items_list,
        items_categories: cs_items_categories,
        items_types: cs_items_types,
        offers: cs_offers,
        calculators: cs_calculators,
        tenants_list: cs_tenants_list,
        page_admin: cs_page_admin,
        pages: cs_pages,
        vs_generator: cs_vs_generator,
    },
    sk: {
        auth: sk_auth,
        general: sk_general,
        chart: sk_chart,
        item_detail: sk_item_detail,
        items_list: sk_items_list,
        items_categories: sk_items_categories,
        items_types: sk_items_types,
        offers: sk_offers,
        calculators: sk_calculators,
        tenants_list: sk_tenants_list,
        page_admin: sk_page_admin,
        pages: sk_pages,
        vs_generator: sk_vs_generator,
    }
};
