<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('offers.offers') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <div class="card p-3">
        <div class="card-body">
          <CalculatorViewWithFiltersComponent ref="calculatorView"></CalculatorViewWithFiltersComponent>
        </div>
      </div>
    </div>
  </div>
</template>
