<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('calculators.calculatorsForClients') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <CalculatorsForClientsViewComponent></CalculatorsForClientsViewComponent>
    </div>
  </div>
</template>