<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="px-3">
          <div v-if="multiple" class="row mb-3"><label class="form-label">{{
              $t('calculators.calculatorsCount')
            }}:</label>
            <input class="form-control" type="number" v-model="calculatorCount" min="1" max="5"/>
          </div>
        </div>
        <div class="col-12">
          <div v-for="(calculator, index) in calculators" :key="index" class="card mb-3">
            <div class="card-header">
              {{ $t('calculators.calculatorRPProcessingFee') }}
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.strategy') }}:</label>
                  <select class="form-select" aria-label="Fee Type" v-model="calculator.strategy"
                          @change="updateCalculation(index)">
                    <option v-for="(strategy, index) in strategies" :value="strategy" :key="index">{{
                        strategy.name
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.feeType') }}:</label>
                  <select class="form-select" aria-label="Fee Type" v-model="calculator.feeType"
                          @change="updateCalculation(index)">
                    <option v-for="(type, index) in feeTypesOptions" :value="type.value" :key="index">{{
                        type.label
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.prepaidTime') }}:</label>
                  <input class="form-control" type="number" v-model="calculator.prepaidTimeYears"
                         @change="updateCalculation(index)" min="0">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.oneTimeInvestmentAmount') }}:</label>
                  <input class="form-control" type="number" v-model="calculator.oneTimeInvestmentAmount"
                         @change="updateCalculation(index)" min="0">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.regularInvestmentAmount') }}:</label>
                  <input class="form-control" type="number" v-model="calculator.regularInvestmentAmount"
                         @change="updateCalculation(index)" min="0">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.currency') }}:</label>
                  <select class="form-select" aria-label="Currency" v-model="calculator.currency"
                          @change="updateCalculation(index)">
                    <option v-for="(currency, index) in currencyOptions" :value="currency.value" :key="index">{{
                        currency.label
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label">{{ $t('calculators.feeDiscount') }}:</label>
                  <input class="form-control" type="number" step="5" min="0" max="100" v-model="calculator.feeDiscount"
                         @change="updateCalculation(index)">
                </div>
              </div>
            </div>
            <div class="px-3 border-top mt-4">
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.totalInvestment') }}:</label>
                  <input class="form-control" disabled
                         :value="formatTotalInvestment(calculator.totalInvestment, calculator)">
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeAfterDiscountPercents') }}:</label>
                  <input class="form-control" disabled
                         :value="formatFeeAfterDiscountPercents(calculator.feeAfterDiscountPercents, calculator)">
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeAfterDiscountAmount') }}:</label>
                  <input class="form-control" disabled
                         :value="formatFeeAfterDiscountAmount(calculator.feeAfterDiscountAmount, calculator)">
                </div>
              </div>
<!--              <div class="row mb-3 mt-3">
                <div class="col-12 ">
                  <label class="form-label">{{ $t('calculators.feeFirstInvestment') }}:</label>
                  <input class="form-control" disabled
                         :value="formatFeeFirstInvestment(calculator.feeFirstInvestment, calculator)">
                </div>
              </div>-->
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-12">
                  <div v-for="(calculator, index) in calculators" :key="index">
                    <div v-if="calculator.totalInvestment" class="border-bottom mb-3">
                      <div class="row mb-3">
                        <div class="col">{{ $t('calculators.strategy') }}</div>
                        <div class="col-auto" id="strategy">{{ calculator.strategy?.name }}</div>
                      </div>
                      <div class="row mb-3 mt-3">
                        <div class="col">{{ $t('calculators.totalInvestmentText') }}</div>
                        <div class="col-auto" id="totalInvestment">
                          {{ formatTotalInvestment(calculator.totalInvestment, calculator) }}
                        </div>
                      </div>
                      <div class="row mb-3 mt-3">
                        <div class="col">{{ $t('calculators.feeType') }}</div>
                        <div class="col-auto" id="feeType">{{ $t('calculators.' + calculator.feeType) }}</div>
                      </div>
                      <div class="row mb-3 mt-3">
                        <div class="col">{{ $t('calculators.feeAfterDiscountPercents') }}</div>
                        <div class="col-auto" id="feeAfterDiscountPercents">
                          {{ formatFeeAfterDiscountPercents(calculator.feeAfterDiscountPercents, calculator) }}
                        </div>
                      </div>
                      <div class="row mb-3 mt-3">
                        <div class="col">{{ $t('calculators.feeAfterDiscountAmount') }}</div>
                        <div class="col-auto" id="feeAfterDiscountAmount">
                          {{ formatFeeAfterDiscountAmount(calculator.feeAfterDiscountAmount, calculator) }}
                        </div>
                      </div>
<!--                      <div class="row mb-3 mt-3">
                        <div class="col">{{ $t('calculators.feeFirstInvestment') }}</div>
                        <div class="col-auto" id="feeFirstInvestment">
                          {{ formatFeeFirstInvestment(calculator.feeFirstInvestment, calculator) }}
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="row mb-3 mt-3">
                    <div class="row">
                      <div class="col" id="resultText">{{ $t('calculators.resultText') }}</div>
                    </div>
                  </div>
                  <div class="row mb-3 mt-3 d-flex justify-content-end">
                    <div class="col-auto">
                      <button class="btn btn-primary" @click="copyToClipboard">{{ $t('calculators.copy') }}</button>
                    </div>
                  </div>
                  <div v-if="isCopied" class="alert alert-danger mt-3" role="alert">
                    {{ $t('calculators.textAfterCopying') }}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http.js";

export default {
  props: ['multiple'],

  data() {
    return {
      strategies: [],
      strategy: null,
      feeTypesOptions: [
        {
          label: this.$t('calculators.processingFee'),
          value: 'processingFee',
        },
        {
          label: this.$t('calculators.processingFeeAccelerated'),
          value: 'processingFeeAccelerated',
        },
        {
          label: this.$t('calculators.processingFeeExpress'),
          value: 'processingFeeExpress',
        }
      ],
      currencyOptions: [
        {
          label: 'CZK',
          value: 'CZK',
        },
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'EUR',
          value: 'EUR',
        }
      ],
      inputRefreshDataTimeout: null,
      calculatorCount: 1,
      calculators: [
        {
          currency: null,
          feeType: null,
          prepaidTimeYears: 0,
          regularInvestmentAmount: 0,
          feeDiscount: 0,
          totalInvestment: 0,
          feeAfterDiscountPercents: 0,
          feeAfterDiscountAmount: 0,
          feeFirstInvestment: 0,
          oneTimeInvestmentAmount: 0,
          strategy: {fee: 0},
          regularInvestmentAmountForCalculation: 0,
        },
      ],
      isCopied: false,
    };
  },

  mounted() {
    this.getAllStrategies();
  },

  computed: {},

  methods: {
    getAllStrategies() {
      let self = this;
      let url = '/items/get-all-strategies';

      if (self.$route.query.locale) {
        url = url + '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        url = url + '?locale=' + this.$i18n.locale;
      } else {
        url = url + '?locale=cs';
      }

      if (window.tenant_code) {
        url = url + '&tenant_code=' + window.tenant_code;
      }

      http.get(url).then((response) => {
        self.strategies = response.data.items;
      });
    },

    updateCalculation(index) {
      let self = this;
      if (this.inputRefreshDataTimeout)
        clearTimeout(this.inputRefreshDataTimeout);

      this.inputRefreshDataTimeout = setTimeout(() => {
        self.calculate(index);
      }, 500);
    },

    calculate(index) {
      let calculator = this.calculators[index];
      calculator.regularInvestmentAmountForCalculation = calculator.regularInvestmentAmount && calculator.prepaidTimeYears ? calculator.regularInvestmentAmount * calculator.prepaidTimeYears * 12 : calculator.regularInvestmentAmount;
      calculator.totalInvestment = (calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount;

      if (calculator.feeDiscount) {
        if (calculator.feeDiscount > 100) {
          calculator.feeDiscount = 100;
        }
        calculator.feeAfterDiscountPercents = (1 - (calculator.feeDiscount / 100)) * calculator.strategy.fee;
        calculator.feeAfterDiscountAmount = ((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100;
      } else {
        calculator.feeAfterDiscountPercents = calculator.strategy?.fee;
        calculator.feeAfterDiscountAmount = ((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100;
      }

      if (calculator.feeType) {
        calculator.feeFirstInvestment = this.calculateProcessingFee(calculator);
      }
    },

    calculateProcessingFee(calculator) {
      if (calculator.feeType === 'processingFee') {
        return (calculator.regularInvestmentAmount + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100;
      } else if (calculator.feeType === 'processingFeeAccelerated') {
        if ((((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100) < calculator.regularInvestmentAmount) {
          return ((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100 * 0.5;
        } else
          return (calculator.regularInvestmentAmount + calculator.oneTimeInvestmentAmount) * 0.5;
      } else if (calculator.feeType === 'processingFeeExpress') {
        if ((((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100) < calculator.regularInvestmentAmount) {
          return ((calculator.regularInvestmentAmountForCalculation) + calculator.oneTimeInvestmentAmount) * calculator.feeAfterDiscountPercents / 100;
        } else
          return calculator.regularInvestmentAmount + calculator.oneTimeInvestmentAmount;
      }
    },

    formatAmount(value, calculator) {
      let currency = calculator.currency ?? 'CZK';

      if (currency) {
        let formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      } else {
        return value.toLocaleString() + ',-';
      }
    },

    formatPercents(value) {
      //let percents = value * 100;
      return value.toFixed(2) + ' %';
    },

    formatTotalInvestment(value, calculator) {
      return this.formatAmount(value ?? 0, calculator);
    },

    formatFeeAfterDiscountAmount(value, calculator) {
      return this.formatAmount(value ?? 0, calculator);
    },

    formatFeeAfterDiscountPercents(value, calculator) {
      return this.formatPercents(value ?? 0, calculator);
    },

    formatFeeFirstInvestment(value, calculator) {
      return this.formatAmount(value ?? 0, calculator);
    },

    /*hasResults() {
      return this.calculators.some(calculator => calculator.totalInvestment > 0);
    },*/

    copyToClipboard() {
      // Získání textu z jednotlivých elementů
      /*const strategy = document.getElementById('strategy').innerText;
      const feeType = document.getElementById('feeType').innerText;
      const totalInvestment = document.getElementById('totalInvestment').innerText;
      const feeAfterDiscountPercents = document.getElementById('feeAfterDiscountPercents').innerText;
      const feeAfterDiscountAmount = document.getElementById('feeAfterDiscountAmount').innerText;
      const feeFirstInvestment = document.getElementById('feeFirstInvestment').innerText;
      */
      const resultText = document.getElementById('resultText').innerText;

      // Vytvoření textu, který bude zkopírován
      let textToCopy = this.calculators.filter(calculator => calculator.totalInvestment > 0).map((calculator, index) => {
        return `
        ${this.$t('calculators.strategy')}: ${calculator.strategy?.name}
        ${this.$t('calculators.totalInvestmentText')}: ${this.formatTotalInvestment(calculator.totalInvestment, calculator)}
        ${this.$t('calculators.feeType')}: ${this.$t('calculators.' + calculator.feeType)}
        ${this.$t('calculators.feeAfterDiscountPercents')}: ${this.formatFeeAfterDiscountPercents(calculator.feeAfterDiscountPercents, calculator)}
        ${this.$t('calculators.feeAfterDiscountAmount')}: ${this.formatFeeAfterDiscountAmount(calculator.feeAfterDiscountAmount, calculator)}
        `;
      }).join('\n\n');

      textToCopy += resultText;

      // Zkopírování textu do schránky
      navigator.clipboard.writeText(textToCopy.trim()).then(() => {
        /*alert('Text byl úspěšně zkopírován do schránky!');*/
      }).catch(err => {
        console.error('Zkopírování do schránky selhalo', err);
      });

      this.isCopied = true;
    }
  },

  watch: {
    calculatorCount(newCount) {
      // Při změně počtu kalkulaček přizpůsobíme pole calculators
      const countDiff = newCount - this.calculators.length;
      if (countDiff > 0) {
        // Přidat nové kalkulačky
        for (let i = 0; i < countDiff; i++) {
          this.calculators.push({
            strategy: null,
            feeType: null,
            prepaidTimeYears: 0,
            regularInvestmentAmount: 0,
            currency: null,
            feeDiscount: 0,
          });
        }
      } else {
        // Odstranit nadbytečné kalkulačky
        this.calculators.splice(newCount);
      }
    }
  },
};
</script>