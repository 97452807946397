<template>
  <div>
    <!--    <div class="row mb-3 justify-content-center">
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-4">
                <input
                    v-model="selectedCalculator"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option2"
                    autocomplete="off"
                    value="calculatorRP"
                    @change="calculatorSelected()"
                />
                <label class="btn btn-outline-primary d-block" for="option2">{{
                    $t('calculators.calculatorRP')
                  }}</label>
              </div>
              <div class="col-4">
                <input
                    v-model="selectedCalculator"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option1"
                    autocomplete="off"
                    value="calculatorKS"
                    @change="calculatorSelected()"
                />
                <label class="btn btn-outline-primary d-block" for="option1">{{
                    $t('calculators.calculatorKS')
                  }}</label>
              </div>
              <div class="col-4">
                <input
                    v-model="selectedCalculator"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option3"
                    autocomplete="off"
                    value="calculatorMultiple"
                    @change="calculatorSelected()"
                />
                <label class="btn btn-outline-primary d-block" for="option3">{{
                    $t('calculators.calculatorMultiple')
                  }}</label>
              </div>
            </div>
          </div>
        </div>-->

    <div class="row justify-content-center">
      <div class="col-12 col-xl-6">
        <CalculatorForClientsComponent></CalculatorForClientsComponent>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/http';

export default {
  props: [],
  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {},
};
</script>