<template>
  <div>
    <!--    <div v-if="isLoading" id="loader-wrap">
          <div class="loader">
            <div class="loader-content">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="mt-3">Načítám...</p>
            </div>
          </div>
        </div>-->
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ page?.title }}</h3>
      </div>
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link
                    :to="{ name: 'PagesIndexView'}">{{ $t('pages.pages') }}
                </router-link>
              </li>
              <li class="breadcrumb-item" v-if="page?.ancestors.length">
                <router-link
                    v-for="ancestor in page?.ancestors" :key="ancestor.id"
                    :to="{ name: 'PagesDetailView', params: { slug: ancestor.slug } }">{{ ancestor.title }}
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ page?.title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="mb-4 text-end" v-show="editAllowed">
        <button
            class="btn btn-primary"
            @click="openModal(page.id)"
        ><i class="bi bi-file-earmark-plus"></i
        ><span class="ps-2">{{ $t('page_admin.editPage') }}</span></button>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="pages-list-wrap">
            <div class="pages-list-title">
              Podstránky
            </div>
<!--            <div v-if="isLoading">{{ $t('general.loadingData') }}</div>-->
            <div v-if="isLoading">
              <div class="loader">
                <div class="loader-content d-flex align-items-center">
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span class="ms-2">{{ $t('general.loadingData') }}</span>
                </div>
              </div>
            </div>
            <ul class="pages-list" v-if="page?.children.length">
              <li v-for="child in page.children" :key="child.slug" class="d-flex d-inline align-items-center">
                <div v-show="editAllowed" class="buttons-group me-3">
                  <button type="button" class="btn btn-sm border me-2" :class="{ 'grey-arrow': child.is_first}"
                          :disabled="child.is_first"
                          @click="moveUp(child.id)">▲
                  </button>
                  <button type="button" class="btn btn-sm border" :class="{ 'grey-arrow': child.is_last}"
                          :disabled="child.is_last"
                          @click="moveDown(child.id)">▼
                  </button>
                </div>
                <router-link :to="{ name: 'PagesDetailView', params: { slug: child?.slug } }" class="pages-list-item">
                  <span class="pages-list-item-title">{{ child.title }} <i class="bi bi-eye-slash"
                                                                           v-if="!child.is_visible"></i><i
                      class="bi bi-chevron-right"></i></span>
                </router-link>
              </li>
            </ul>
          </div>

        </div>
        <div class="col-12 col-md-9">
          <div class="card mb-3">
            <div class="card-body">
<!--              <div v-if="isLoading">{{ $t('general.loadingData') }}</div>-->
              <div v-if="isLoading">
                <div class="loader">
                  <div class="loader-content d-flex align-items-center">
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span class="ms-2">{{ $t('general.loadingData') }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="card-text pb-3 mb-2 border-bottom fw-bold">{{ page?.description ? page?.description : "---"}}</div>
                <div class="card-text" id="content-text" v-html="page?.content"></div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div v-if="page?.documents.length">
            <DocumentsCardComponent :page="page"></DocumentsCardComponent>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="PageAdminModal" :pages="pages"/>
  </div>
</template>

<script>
import http from '@/http';
import TokenService from "@/services/token-service.js";
import Modal from "@/components/page_admin/PageAdminModal.vue";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      page: {children: [], ancestors: [], documents: []},
      pages: [],
      isLoading: false,
      editAllowed: false,
    };
  },

  mounted() {
    this.getPage();
    this.loadPages();
    this.emitter.on('pageSaved', (page) => {
      this.loadPages();
      this.page = page;
    });
    this.emitter.on('documentUploaded', () => {
      this.getPage();
    });
    this.canEdit();
  },

  watch: {
    '$route.params.slug': 'getPage'
  },

  methods: {
    getPage() {
      let self = this;
      self.isLoading = true;
      http.get('/pages/' + self.$route.params.slug).then((response) => {
        self.page = response.data.page;
        self.isLoading = false;
        self.page.children = self.page.children.filter(page => {
          if (this.editAllowed) {
            return true;
          }
          const tenantCode = window.settings.tenant_code;
          if (tenantCode == null) {
            return page.for_tenants == null;
          }

          // Pokud page.for_tenants je null nebo undefined, považujeme to za prázdné pole
          const isForTenant = Array.isArray(page.for_tenants) && page.for_tenants.includes(tenantCode);

          // Zobrazí pouze root stránky, které jsou určeny pro daného tenant ID
          return isForTenant;
        });
      });
    },

    loadPages() {
      let self = this;
      self.isLoading = true;
      http.get('/pages?list=simple').then((response) => {
        self.pages = response.data.pages;
        self.isLoading = false;
      });
    },

    openModal(id) {
      let page = this.pages.find(page => page.id === id);
      this.$refs.PageAdminModal.toggleModal(page);
    },

    canEdit() {
      this.editAllowed = TokenService.getRoleLevel() === 5;
    },

    moveUp(id) {
      let clickedPageIndex = this.page.children.findIndex(page => page.id === id);
      let previousPageId = this.page.children[clickedPageIndex - 1].id;
      let body = {
        previous_page_id: previousPageId
      };

      http.post('/pages/' + id + '/move-up', body)
          .then(response => {
            this.getPage();
          })
          .catch(error => {
            console.error(error);
          });
    },

    moveDown(id) {
      let clickedPageIndex = this.page.children.findIndex(page => page.id === id);
      let nextPageId = this.page.children[clickedPageIndex + 1].id;
      let body = {
        next_page_id: nextPageId
      };

      http.post('/pages/' + id + '/move-down', body)
          .then(response => {
            this.getPage();
          })
          .catch(error => {
            console.error(error);
          });
    },
  }
};
</script>
