import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import mitt from 'mitt';
import TenantService from "@/services/tenant-service.js";

const emitter = mitt();

// Plugin import
import VueApexCharts from "vue3-apexcharts";
import VueGoodTablePlugin from 'vue-good-table-next';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueMultiselect from 'vue-multiselect';
import { QuillEditor } from '@vueup/vue-quill';
import DropZone from 'dropzone-vue';

// Plugin styles
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';

// Localizations import
import {createI18n} from 'vue-i18n';
import {languages} from './locales/index.js';

// Component import
import ChartViewComponent from './components/items/ChartViewComponent.vue';
//import EChartViewComponent from './components/items/EChartViewComponent.vue';
import InfoCardComponent from './components/items/InfoCardComponent.vue';
import ChartViewWithFiltersComponent from './components/items/ChartViewWithFiltersComponent.vue';
import CalculatorViewWithFiltersComponent from './components/items/CalculatorViewWithFiltersComponent.vue';
import CalculatorsViewComponent from './components/calculators/CalculatorsViewComponent.vue';
import CalculatorsForClientsViewComponent from './components/calculators/CalculatorsForClientsViewComponent.vue';
import CalculatorKSComponent from './components/calculators/CalculatorKSComponent.vue';
import CalculatorRPComponent from './components/calculators/CalculatorRPComponent.vue';
import CalculatorForClientsComponent from './components/calculators/CalculatorForClientsComponent.vue';
import DropzoneComponent from './components/page_admin/DropzoneComponent.vue';
import DocumentsCardComponent from './components/pages/DocumentsCardComponent.vue';

// Fontawesome icons
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const app = createApp(App);

const i18n = createI18n({
    locale: 'cs',
    fallbackLocale: 'sk',
    legacy: false,
    messages: {
        cs: languages.cs,
        sk: languages.sk,
    },
});

// Sentry
import * as Sentry from "@sentry/vue";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: "https://8f5f8585d831801fd4d9f7b8f5a794aa@o4506415846195200.ingest.sentry.io/4506711319314432",
        org: 'inove-dev',
        //enabled: process.env.NODE_ENV === 'production',
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/produkty.in-equity\.cz/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(router);
app.use(i18n);
app.use(VueApexCharts);
app.use(VueGoodTablePlugin);
app.use(DropZone);
app.component('VueDatePicker', VueDatePicker);
app.component('VueMultiselect', VueMultiselect);
app.component('ChartViewComponent', ChartViewComponent);
//app.component('EChartViewComponent', EChartViewComponent);
app.component('ChartViewWithFiltersComponent', ChartViewWithFiltersComponent);
app.component('CalculatorViewWithFiltersComponent', CalculatorViewWithFiltersComponent);
app.component('InfoCardComponent', InfoCardComponent);
app.component('CalculatorsViewComponent', CalculatorsViewComponent);
app.component('CalculatorsForClientsViewComponent', CalculatorsForClientsViewComponent);
app.component('CalculatorsKSComponent', CalculatorKSComponent);
app.component('CalculatorsRPComponent', CalculatorRPComponent);
app.component('CalculatorForClientsComponent', CalculatorForClientsComponent);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('QuillEditor', QuillEditor);
app.component('DropzoneComponent', DropzoneComponent);
app.component('DocumentsCardComponent', DocumentsCardComponent);

app.config.globalProperties.emitter = emitter;

/*const url = new URL(window.location.href);
const queryParams = new URLSearchParams(url.search);
let tenantCode = queryParams.get('tenant_code') ?? 'default';
app.config.globalProperties.settings = TenantService.getTenantSettings(tenantCode);*/
app.config.globalProperties.settings = TenantService.getTenantSettings('default');
window.settings = TenantService.getTenantSettings('default');
app.config.globalProperties.themeSettings = {};

router.isReady().then(() => {
    app.mount('#app');
});