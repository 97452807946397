<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('calculators.calculators') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <CalculatorsViewComponent ref="calculatorView"></CalculatorsViewComponent>
    </div>
  </div>
</template>