<template>
  <div class="filter">
    <div v-if="!itemFromDetail">
      <div class="row mb-3">
        <div class="col-12 col-lg-6 mt-2">
          <label class="form-label">{{ $t('chart.categories') }}:</label>
          <div>
            <div class="row">
              <div class="col-6">
                <input
                    v-model="selectedCategory"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option1"
                    autocomplete="off"
                    value="strategies"
                    @change="prepareAvailableProducts()"
                />
                <label class="btn btn-outline-primary d-block" for="option1">{{
                    $t('chart.strategies')
                  }}</label>
              </div>
              <div class="col-6">
                <input
                    v-model="selectedCategory"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option2"
                    autocomplete="off"
                    value="stocks"
                    @change="prepareAvailableProducts()"
                />
                <label class="btn btn-outline-primary d-block" for="option2">{{
                    $t('chart.stocks')
                  }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 mt-2">
          <label class="form-label">{{ $t('chart.product') }}:</label>
          <VueMultiselect
              v-model="selectedProduct"
              :options="filteredProducts"
              :object="false"
              label="name"
              valueProp="code"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$t('chart.selectProduct')"
              @select="getItem()"
          >
          </VueMultiselect>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-6 mt-4">
        <div>
          <label class="form-label">{{ $t('chart.show') }}:</label>
          <div class="d-flex">
            <div
                class="form-check me-3"
                v-for="availableDataChoice in availableDataChoices"
                :key="availableDataChoice"
            >
              <input
                  class="form-check-input"
                  type="radio"
                  name="value-check"
                  :id="'value-check-' + availableDataChoice"
                  v-model="dataChoice"
                  :value="availableDataChoice"
                  @change="selectedDataChoice()"
              />
              <label class="form-check-label" :for="'value-check-' + availableDataChoice">
                {{
                  availableDataChoice === 'percentage'
                      ? $t('chart.percentage')
                      : $t('chart.price')
                }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 mt-4">
        <div class="row">
          <div class="col-12 col-md-12 mt-2">
            <label class="form-label">{{ $t('chart.forPeriod') }}:</label>
            <VueMultiselect
                v-model="selectedYear"
                :options="years"
                :close-on-select="true"
                :show-labels="false"
                :allow-empty="false"
                :placeholder="$t('chart.selectYear')"
                @select="updateRange()"
            >
            </VueMultiselect>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 mt-4">
        <div class="row">
          <div>
            <div class="form-check">
              <label class="form-label">{{ $t('chart.compare') }}</label>
              <input
                  class="form-check-input"
                  type="checkbox"
                  name="compare-check"
                  id="compare-check"
                  v-model="showCompare"
              />
            </div>
          </div>
          <div v-if="showCompare">
            <VueMultiselect
                v-model="selectedProductForComparison"
                :options="products"
                :close-on-select="true"
                :allow-empty="false"
                track-by="id"
                label="name"
                :object="false"
                :placeholder="$t('chart.selectProduct')"
                @select="updateRange()"
            >
            </VueMultiselect>
          </div>
        </div>
      </div>

    </div>


    <div class="card mt-4">
      <div class="card-body">
        <ChartViewComponent
            ref="chart"
            :code="selectedProduct?.code"
            :dataChoice="dataChoice"
            :from="from"
            :to="to"
            :embedded="embedded"
            :compare="showCompare"
            :productCompareCode="selectedProductForComparison?.code"
        ></ChartViewComponent>
      </div>
    </div>
    <div class="chart-disclaimer mt-4">* {{ $t('chart.disclaimer') }}</div>
  </div>

</template>

<script>
import http from '@/http';

export default {
  props: ['itemFromDetail', 'embedded'],
  data() {
    return {
      item: {...this.itemFromDetail},
      selectedFrom: null,
      selectedTo: null,
      from: new Date().getFullYear() + '-01-01',
      to: null,
      product: {...this.itemFromDetail},
      products: [],
      filteredProducts: [],
      selectedProduct: this.itemFromDetail ? {...this.itemFromDetail} : null,
      isLoading: false,
      selectedYear: 'YTD',
      years: [],
      customRange: false,
      dataChoice: 'percentage',
      availableDataChoices: [],
      options: [],
      selectedCategory: 'strategies',
      initialDeposit: null,
      regularDeposit: null,
      stats: [],
      inputRefreshDataTimeout: null,
      pdfIsGenerating: false,
      showCompare: false,
      selectedProductForComparison: [],
    };
  },

  mounted() {
    if (!this.itemFromDetail) {
      this.getAllProducts();
    } else {
      this.fillYearsOfDuration(this.itemFromDetail);
      this.selectedCategory =
          this.itemFromDetail.type === 'STRATEGY' ? 'strategies' : 'stocks';
      this.fillAvailableDataChoices();
      this.getAllProducts();
    }

    this.emitter.on('statsLoaded', (stats) => {
      this.stats = stats;
    });
  },

  methods: {
    prepareAvailableProducts() {
      let self = this;
      if (this.selectedCategory === 'strategies') {
        self.fillAvailableDataChoices();
        self.filteredProducts = this.products.filter((product) => product.type === 'STRATEGY');
        self.selectedProduct = self.filteredProducts[0];
        self.getItem();
      } else {
        self.fillAvailableDataChoices();
        self.filteredProducts = this.products.filter((product) => product.type !== 'STRATEGY');
        self.selectedProduct = self.filteredProducts[0];
        self.getItem();
      }
    },

    selectedDataChoice() {
      this.refreshData();
    },

    categorySelected() {
      if (!this.itemFromDetail) {
        this.selectedProduct = this.item;
        this.refreshData();
      } else {
        this.selectedProduct = this.filteredProducts[0];
      }
      this.getOptions();
    },

    fillAvailableDataChoices() {
      if (this.selectedCategory === 'strategies') {
        this.availableDataChoices = ['percentage'];
      } else {
        this.availableDataChoices = ['price', 'percentage'];
      }
      this.dataChoice = this.availableDataChoices[0];
    },

    getAllProducts() {
      let self = this;
      let url = '/items/get-all';
      if (self.$route.query.locale) {
        url = url + '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        url = url + '?locale=' + this.$i18n.locale;
      } else {
        url = url + '?locale=cs';
      }
      if (window.tenant_code) {
        url = url + '&tenant_code=' + window.tenant_code;
      }

      http.get(url).then((response) => {
        self.products = response.data.items;
      });
    },

    getOptions() {
      let self = this;
      let checkObject = null;
      self.options = [];
      if (self.filteredProducts) {
        self.selectedProduct = self.filteredProducts[0];
        for (let key in self.filteredProducts) {
          checkObject = self.filteredProducts[key];
          if (checkObject.public) {
            self.options.push(checkObject);
          }
        }
      }
    },

    getItem() {
      let self = this;
      http.get('/items/' + self.selectedProduct.code).then((response) => {
        self.item = response.data.item;
        self.fillYearsOfDuration(self.item);
        self.updateRange();
        self.fillAvailableDataChoices();
      });
    },

    fillYearsOfDuration(item) {
      let self = this;
      self.years = [];
      self.years = item.years_of_duration;
      self.years[0] = 'YTD';
      self.years[self.years.length - 1] =
          self.years[self.years.length - 1] +
          ' (' +
          self.$t('chart.dateOfFoundation') +
          ': ' +
          new Date(item.foundation_date).toLocaleDateString() +
          ')';
      //self.years[self.years.length] = self.$t('chart.fromStart');
      //self.years[self.years.length] = this.$t('chart.customPeriod');
    },

    updateRange() {
      let self = this;
      if (self.selectedYear !== this.$t('chart.customPeriod')) {
        if (self.selectedYear.startsWith('YTD')) {
          self.from = new Date().getFullYear() + '-01-01';
          self.to = new Date().toISOString().split('T')[0];
        } else if (self.selectedYear === self.$t('chart.fromStart')) {
          self.customRange = true;
          self.from = self.item.foundation_date.split('T')[0];
          self.to = new Date().toISOString().split('T')[0];
        } else {
          self.selectedYear = self.selectedYear.slice(0, 4);
          self.from = self.selectedYear + '-01-01';
          self.to = self.selectedYear + '-12-31';
        }
      } else {
        self.from = self.selectedFrom;
        self.to = self.selectedTo;
      }

      setTimeout(function () {
        self.refreshData();
      }, 200);

      self.emitter.emit('filterApplied', {
        year: self.selectedYear,
        from: self.from,
        to: self.to,
      });
    },

    refreshData() {
      this.$refs.chart.getChartData();
    },
  },

  computed: {},
};
</script>