<template>
  <div class="entrance-form w-100 justify-content-center">
    <div class="card entrance-form--card">
      <form @submit.prevent="login()" class="m-3">
        <img class="mb-4 entrance-logo" src="@/images/logos/InEquity-logo-colored.svg" alt="Nějaké logo"/>
        <h1 class="h3 mb-3 fw-normal">{{ $t('auth.login') }}</h1>

        <div class="form-floating mb-3">
          <input
              type="email"
              class="form-control"
              placeholder="name@example.com"
              v-model="email"
          />
          <label for="floatingInput">{{ $t('auth.email') }}</label>
        </div>
        <div class="form-floating mb-3">
          <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="password"
          />
          <label for="floatingPassword">{{ $t('auth.password') }}</label>
        </div>

        <button class="w-100 btn btn-lg btn-primary">{{ $t('auth.loginCommand') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import TokenService from '@/services/token-service';

export default {
  data() {
    return {
      email: null,
      password: null,
    };
  },
  mounted() {
  },

  computed: {},

  methods: {
    login() {
      let self = this;
      let body = {
        email: self.email,
        password: self.password,
      };

      http.post('/auth/login', body).then((response) => {


        //http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

        // kontrola, zda to vratilo 200 a uzivatele s tokenem
        if (response.status === 200 && response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
          let user = response.data.user;
          TokenService.setUser(user);
          window.location = '/';
        }
      });
    },
  },
};
</script>
