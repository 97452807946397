<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header">
            {{ $t('calculators.calculatorServiceFee') }}
          </div>
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">{{ $t('calculators.strategy') }}:</label>
                <select class="form-select" aria-label="Fee Type" v-model="strategy"
                        @change="updateCalculation">
                  <option v-for="(strategy, index) in strategies" :value="strategy" :key="index">{{
                      strategy.name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div v-show="false" class="row mb-3">
              <div class="col-12">
                <label class="form-label">{{ $t('calculators.feeType') }}:</label>
                <select class="form-select" aria-label="Fee Type" v-model="feeType">
                  <!--                  <option v-for="(type, index) in feeTypesOptions" :value="type.value" :key="index">{{
                                        type.label
                                      }}
                                    </option>-->
                  <option :value="feeTypesOptions[0].value" selected disabled>{{
                      feeTypesOptions[0].label
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">{{ $t('calculators.timeOfInvestmentInYears') }}:</label>
                <input class="form-control" type="number" v-model="timeOfInvestmentInYears"
                       @change="updateCalculation" min="0">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">{{ $t('calculators.regularInvestmentAmount') }}:</label>
                <input class="form-control" type="number" v-model="regularInvestmentAmount"
                       @change="updateCalculation" min="0">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">{{ $t('calculators.currency') }}:</label>
                <select class="form-select" aria-label="Currency" v-model="currency"
                        @change="updateCalculation">
                  <option v-for="(currency, index) in currencyOptions" :value="currency.value" :key="index">{{
                      currency.label
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="px-3 border-top mt-4">
            <div class="row mb-3 mt-3">
              <div class="col-12 ">
                <label class="form-label">{{ $t('calculators.totalInvestment_') }}:</label>
                <input class="form-control" disabled
                       :value="formatAmount(totalInvestment)">
              </div>
            </div>
            <div class="row mb-3 mt-3">
              <div class="col-12 ">
                <label class="form-label">{{ $t('calculators.totalInvestmentAfterFees') }}:</label>
                <input class="form-control" disabled
                       :value="formatAmount(totalInvestmentAfterFees)">
              </div>
            </div>
            <div class="row mb-3 mt-3">
              <div class="col-12 ">
                <label class="form-label">{{ $t('calculators.totalFees') }}:</label>
                <input class="form-control" disabled
                       :value="formatAmount(totalFees)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http.js";

export default {
  props: [],

  data() {
    return {
      strategies: [],
      strategy: {fee: 0, month_evaluation: 0},
      feeTypesOptions: [
        {
          label: this.$t('calculators.serviceFee'),
          value: 'processingFee',
        },
      ],
      currencyOptions: [
        {
          label: 'CZK',
          value: 'CZK',
        },
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'EUR',
          value: 'EUR',
        }
      ],
      inputRefreshDataTimeout: null,
      currency: null,
      feeType: 'processingFee',
      totalInvestment: 0,
      totalInvestmentAfterFees: 0,
      timeOfInvestmentInYears: 0,
      regularInvestmentAmount: 0,
      totalFees: 0,
      wat_sk: 20,
    };
  },

  mounted() {
    this.getAllStrategies();
  },

  computed: {},

  methods: {
    getAllStrategies() {
      let self = this;
      let url = '/items/get-all-strategies';

      if (self.$route.query.locale) {
        url = url + '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        url = url + '?locale=' + this.$i18n.locale;
      } else {
        url = url + '?locale=cs';
      }

      if (window.tenant_code) {
        url = url + '&tenant_code=' + window.tenant_code;
      }

      http.get(url).then((response) => {
        self.strategies = response.data.items;
      });
    },

    updateCalculation(index) {
      let self = this;
      if (this.inputRefreshDataTimeout)
        clearTimeout(this.inputRefreshDataTimeout);

      this.inputRefreshDataTimeout = setTimeout(() => {
        self.calculate(index);
      }, 500);
    },

    calculate() {
      //this.totalInvestment = this.regularInvestmentAmount && this.timeOfInvestmentInYears ? this.regularInvestmentAmount * this.timeOfInvestmentInYears * 12 : this.regularInvestmentAmount;

      let investmentCountMonths = this.timeOfInvestmentInYears * 12;
      let totalAmountOfFees = 0;
      let totalInvestmentAmount = 0;
      let calculatedTotalInvestment = 0;
      let strategyYield = this.strategy?.month_evaluation / 100;
      let wat = 0;
      if (this.$i18n.locale === 'sk') {
        wat = this.wat_sk;
      }

      if (this.strategy && this.regularInvestmentAmount) {
        for (let i = 0; i < investmentCountMonths; i++) {
          let monthAmount = this.regularInvestmentAmount * (strategyYield + 1);
          let fee = monthAmount * (this.strategy?.fee / 100);
          if (wat) {
            fee *= 1 + (wat / 100);
          }
          let monthAmountAfterFee = monthAmount - fee;
          totalAmountOfFees += fee;
          totalInvestmentAmount += monthAmount;
          calculatedTotalInvestment += monthAmountAfterFee;
        }
      }

      this.totalInvestment = totalInvestmentAmount;
      this.totalInvestmentAfterFees = calculatedTotalInvestment;
      this.totalFees = totalAmountOfFees;
    },

    calculateProcessingFee() {
      if (this.feeType === 'processingFee') {
        this.totalInvestmentAfterFees = this.totalInvestment - (this.regularInvestmentAmount * (this.strategy?.fee / 100));
      }
    },

    formatAmount(value) {
      let currency = this.currency ?? 'CZK';

      if (currency) {
        let formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      } else {
        return value.toLocaleString() + ',-';
      }
    },

    formatPercents(value) {
      //let percents = value * 100;
      return value.toFixed(2) + ' %';
    },
  },
};
</script>