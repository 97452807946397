export default function authHeader() {
   /*  let user = JSON.parse(localStorage.getItem('user')); */
    let headers = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Accept-Language': 'cs-CZ,cs;q=0.9',
    };

    /* if (user && user.token) {
      headers['Authorization'] = 'Bearer ' + user.token;
    } */

    return headers;

  }
